<template>
    <div class="block">
        <div class="flex">
            <div class="inline mb-5">
                <div class="inline-block w-20 align-center">{{ $t("Year") }}</div>
                <div class="inline-block select-date">
                    <vs-select class="border-grey-light w-36 rounded-lg" v-model="yearSelected">
                        <vs-select-item v-for="(year, index) in this.years" :key="index" :value="year" :text="year" />
                    </vs-select>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="inline mb-5">
                <div class="inline-block w-20 align-center">{{ $t("Month") }}</div>
                <div class="inline-block select-date">
                    <vs-select class="border-grey-light w-36" v-model="monthSelected">
                        <vs-select-item v-for="(month, index) in this.months" :key="index" :value="month.value" :text="month.name" />
                    </vs-select>
                </div>
            </div>
        </div>
        <div class="mb-5">
            <span>{{ $t("Send to this email") }}</span>
            <div class="select-date">
                <vs-input class="border-grey-light w-72" v-model="email" name="email" v-validate="'required|email'"/>
            </div>
        </div>
        <div class="flex">
            <vs-button @click="exportData()" color="primary" type="filled rounded-lg w-72">
                <span >{{ buttonText }}</span>
            </vs-button>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import Info from 'luxon/src/info.js'

export default {
    name: 'export-form',
    data() {
        return {
            years: [],
            yearSelected: null,
            monthSelected: null,
            email: ''
        }
    },
    props: {
        buttonText: String
    },
    created() {
        this.years = Array.from({length: DateTime.local().year  - 2010}, (value, index) => 2011 + index).reverse()
    },
    computed: {
        ...mapGetters(['applicationLanguage']),
        months() {
            const months = Info.months("long", { locale: this.applicationLanguage }).map((month, index) => ({ name: month, value: index + 1}))
            return this.yearSelected == DateTime.local().year ? months.slice(0, DateTime.local().month) : months;
        }
    },
    methods: {
        exportData() {
            this.$validator.validateAll().then((result) => {
                if (this.yearSelected && this.monthSelected && result) {
                    this.$emit('exportData', {
                        year: this.yearSelected,
                        month: this.monthSelected,
                        email: this.email
                    })
                } else {
                    this.$vs.notify({
                        text: this.$t("Fill all the fields to export the data"),
                        color: 'danger', 
                        position:'top-right'
                    })
                }
            });
        }
    }
}
</script>

<style>
.select-date input {
    @apply h-8
}
</style>

